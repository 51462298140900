import React, { useEffect } from 'react';
import './assets/styles/App.css';
import { Navigate, Routes, Route, useLocation } from "react-router-dom";
import Header from './app/components/Header';
import WAFloatingButton from './app/components/WAFloatingButton';
import Home from './app/views/Home';
import Favorites from './app/views/Favorites';
import Footer from './app/components/Footer';
import Post from './app/views/Post';
import AboutUs from './app/views/AboutUs';
import FrequentlyQuestions from './app/views/FrequentlyQuestions';
import BeOurPartner from './app/views/BeOurPartner';
import DetailView from './app/views/Detail';
import Result from './app/views/Result';

import '@coreui/coreui/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@coreui/coreui/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/App.css';


function App({ p }) {
  let path = useLocation().pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [path]);

  return (
    <>
      <Header />
      <WAFloatingButton />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/publicar-inmueble" element={<Post />} />
        <Route path="/quienes-somos" element={<AboutUs />} />
        <Route path="/preguntas-frecuentes" element={<FrequentlyQuestions />} />
        <Route path="/favoritos" element={<Favorites />} />
        <Route path="/se-nuestro-aliado" element={<BeOurPartner />} />
        <Route path="/detalle/:cod_inmueble" element={<DetailView />} />
        <Route path="/resultado" element={<Result />} />
        <Route path='/*' element={<Navigate to="/" />} />
      </Routes>
      {path != "/resultado" && <Footer />}
    </>
  );
}

export default App;
